import React from "react";
import * as _ from "lodash";
import styled from "styled-components";

import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

import { ChildrenList } from "./shared/ChildrenList";
import { PageTitle } from "./shared/PageTitle";
class AllSetsList extends React.Component {
  render() {
    let posts = _.map(this.props.data.allMarkdownRemark.edges, e => e.node);
    return <ChildrenList title={<PageTitle>image sets</PageTitle>} data={posts} />;
  }
}

AllSetsList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query AllSetsLists {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___sortOrder] }
          filter: { frontmatter: { templateKey: { eq: "set-detail" }, parent: { eq: null } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
                childForImage {
                  id
                  frontmatter {
                    entityType
                    title
                    image {
                      publicURL
                    }
                  }
                }
              }
              frontmatter {
                image {
                  publicURL
                }
                title
                subtitle
                templateKey
                date(formatString: "MMMM DD, YYYY")
                href
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <AllSetsList data={data} count={count} />}
  />
);
