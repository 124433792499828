import React from "react";

import Layout from "../../components/Layout";
import AllSetsList from "../../components/AllSetsList";

export default class SetIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="container">
          <div className="content">
            <AllSetsList />
          </div>
        </div>
      </Layout>
    );
  }
}
